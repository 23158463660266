import NotFound from "../components/404/NotFound";
import MainLayout from "../components/MainLayout/MainLayout";

export default function Custom404() {
  return (
    <MainLayout>
      <NotFound />
    </MainLayout>
  );
}
